import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography'
import { Image } from '@/components/RichText/RichText.styles'

export const Description = styled(Typography).attrs({
  variant: TextStyles['Small Paragraph Text'],
})`
  text-align: left;
  color: ${({ theme }) => theme.sectionTheme?.lightSubText};

  ${Image} {
    width: 100%;
    object-fit: contain;
  }
`
