import React from 'react'
import { ContentItemInterface } from '@/interfaces/contentItem'
import { Collapse, RichText } from '@/components'
import { Description } from './NestedItem.styles'
import { ContentItemUITemplateProps } from '../../ContentItem'

const NestedItem: React.FC<ContentItemInterface & ContentItemUITemplateProps> = (props) => {
  const {
    title, seoDescription, customProps 
  } = props
  
  if (!customProps) return null
  
  const {
    isOpened, onChanged, index 
  } = customProps

  return (
    <Collapse
      title={title ?? ''}
      isOpened={isOpened}
      onChanged={(isOpened) => onChanged(isOpened, index)}
    >
      {seoDescription && (
        <Description>
          <RichText richText={seoDescription} />
        </Description>
      )}
    </Collapse>
  )
}

export default NestedItem
